<template>
  <graph
    class="current-past-timeline"
    width="100%"
    height="200px"
    :options="chartOptions"
    :series="series"
    v-if="account"
  />
</template>

<script>
export default {
  components: {
    graph: () => import('@/components/graph')
  },
  computed: {
    balances () {
      return this.account?.balances
    },
    chartOptions () {
      return {
        chart: {
          stacked: true,
          type: 'line'
        },
        stroke: {
          width: 0
        },
        labels: this.labels,
        xaxis: {
          type: 'datetime'
        },
        yaxis: {
          labels: {
            formatter: y => this.formatCurrency(y, this.account.currency)
          },
          min: 0
        },
        tooltip: {
          intersect: false,
          y: {
            formatter: y => this.formatCurrency(y, this.account.currency)
          }
        }
      }
    },
    labels () {
      let date = this.account?.date
      if (!date) { return null }

      date = this.moment(date)

      return this.account.days?.map(d => {
        const dd = this.moment(date)
        dd.add(d, 'days')
        return dd.valueOf()
      })
    },
    series () {
      return [{
        name: this.$t('t.Current'),
        type: 'bar',
        data: this.account.current.data
      }, {
        name: this.$t('t.Due'),
        type: 'bar',
        data: this.account.due.data
      }]
    }
  },
  props: {
    account: Object
  }
}
</script>
